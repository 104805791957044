import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { GabinetComponent } from './gabinet/gabinet.component';
import { PraktykaComponent } from './praktyka/praktyka.component';
import { CertyfikatyComponent } from './certyfikaty/certyfikaty.component';
import { ZakresbadanComponent } from './zakresbadan/zakresbadan.component';
import { SliderComponent } from './slider/slider.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from './myservices/data.service';
import { HttpClientModule,  HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AppRoutingModule } from './app-routing.module';
import { GaleriaComponent } from './certyfikaty/galeria/galeria.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselComponent } from './carousel/carousel.component';




@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    KontaktComponent,
    GabinetComponent,
    PraktykaComponent,
    CertyfikatyComponent,
    ZakresbadanComponent,
    SliderComponent,
    GaleriaComponent,
    CarouselComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    ScrollToModule.forRoot(),
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [DataService],
 
  bootstrap: [AppComponent]
})
export class AppModule { }
