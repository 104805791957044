<section id="gabinet" #destinationRef>
    <div class="container-xl">
        
        <div class="row"><h2 class="display-4 font-weight-normal">CERTYFIKATY</h2></div>
        <div class="row" >
          <div class="col-lg-3 col-md-4 col-6"  *ngFor="let card of cards; let cardIndex = index"><a href="#" class="d-block mb-4 h-100"><img class="img-fluid img-thumbnail" src="{{card.name}}" alt=""></a></div>
        
       
  
        </div>
        
    </div>        
  </section>