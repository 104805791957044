import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../myservices/data.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { trigger, state, style, transition, animate, query, useAnimation } from '@angular/animations';
import {   fadeIn,  fadeOut, scaleIn, scaleOut,} from "../myclasses/carouselanimation";

@Component({
  selector: 'app-certyfikaty',
  templateUrl: './certyfikaty.component.html',
  styleUrls: ['./certyfikaty.component.css'],
 
  
 
})
export class CertyfikatyComponent implements OnInit {
 
 showNavigationArrows = true;
 showNavigationIndicators = true;
 products: any[];
 slides: any = [];
 cards: any[];
 i:number=0;
 currentSlide = 0;
 condition:boolean=true;
 isOpen: boolean;
 exp = 'goAnimate';


  constructor(private myData: DataService,/*config: NgbCarouselConfig,*/ private route: ActivatedRoute,) { // customize default values of carousels used by this component tree
   /* config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  config.interval = 3500;*/ }
   
  ngOnInit(): void {
    

  
   
    
  }
  


}




