import { Component, OnInit } from '@angular/core';
import { DataService } from '../myservices/data.service';

@Component({
  selector: 'app-gabinet',
  templateUrl: './gabinet.component.html',
  styleUrls: ['./gabinet.component.css']
})
export class GabinetComponent implements OnInit {

  cards: any[];

  constructor(private myData: DataService) { }

  ngOnInit(): void {
    
    this.myData.getData('../assets/gabinet/gabinet.json').subscribe(
     (data) => { 
       this.cards = data;    
     
         
     });
     
   }
}
