import { Component } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { trigger, transition, useAnimation, query } from '@angular/animations';
import { scaleIn, scaleOut } from '../myclasses/carouselanimation';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  providers: [NgbCarouselConfig],
  animations: [
    
    trigger("certyfikaty", [
      transition("void => *", [ query('.active',[ useAnimation(scaleIn, {params: { time: '3000ms' }} )],{optional:true})]),
      transition("* => void", [query('.active',[ useAnimation(scaleIn, {params: { time: '3000ms' }} )],{optional:true})]),
    ])
  ]
})
export class SliderComponent {
  showNavigationArrows = false;
  showNavigationIndicators = false;
  products = 1;

  constructor(config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.interval = 3500;
  }
  }

