
<section id="certyfikaty" #destinationRef>
  <div class="container ">
    <button type="button" class="btn btn-sm btn-outline-dark float-right"><a outerLinkActive="active" routerLink="/certyfikatyw">Pokaż wszystkie</a></button>
   
  </div>

  <div class="container-xl">
    <div class="container clearfix">           
      <h2 class="display-4 font-weight-normal float-left">Certyfikaty</h2>
   
      
  </div>

  <div class="container clearfix">
    
      <app-carousel [slides]="slides"></app-carousel>
      <div class="row clearfix"></div>
  </div>
  <div style='clear:both'></div>
  </div>
  <div class="row clearfix"></div>

</section>





