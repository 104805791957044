<button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
   
<div *ngIf="cards" style="position: relative;">
    <ng-container ngbSlide  *ngFor="let item of slides,let i = index" >
        <div class="picsum-img-wrapper carousel slide"   >
            <div class="card float-left  " style="max-width: 18rem;" *ngFor="let card of item; let cardIndex = index">
                <div  *ngIf="i === currentSlide" class="galleryItem" style="background-image: url('{{card.name}}');" @certyfikaty> <!-- (@certyfikaty.done)="onDone($event)" -->
                    <div class='title'>
                        {{card.rok}}
                    </div>
            </div><!--[ngClass]="{visible: i===currentSlide, hidden: i!==currentSlide}"-->
            </div>
        </div>
    </ng-container>
</div>

