<ngb-carousel *ngIf="products" >
  <ng-template ngbSlide >
    <div class="picsum-img-wrapper carousel slide" @certyfikaty >
      <div class="galleryItem" style="background-image: url('assets/slider/slider1.jpg');"></div>
    </div>
    <div class="carousel-caption " >
      <h2 class="display-4 font-weight-normal">Opieka przedporodowa i poporodowa</h2>
  <p class="lead font-weight-normal"> SPECJALISTYCZNY GABINET GINEKOLOGICZNO - POŁOŻNICZY</p>
    </div>
  </ng-template>
  <ng-template ngbSlide >
    <div class="picsum-img-wrapper carousel slide">
      <div class="galleryItem" style="background-image: url('assets/slider/slider2.jpg');" @certyfikaty></div>
     
    </div>
    <div class="carousel-caption">
      <div class="carousel-caption d-none d-md-block">
        <h2 class="display-4 font-weight-normal">Leczenie dolegliwości okresu przekwitania, zaburzeń menopauzy</h2>
  <p class="lead font-weight-normal"> SPECJALISTYCZNY GABINET GINEKOLOGICZNO - POŁOŻNICZY</p>
      </div>
    </div>
  </ng-template>



