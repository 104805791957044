import { Component, OnInit } from '@angular/core';
import { ScrollToAnimationEasing,  ScrollToOffsetMap } from '@nicky-lenaers/ngx-scroll-to';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  ngxScrollToDestination: string;
  
  ngxScrollToDuration: number;
  ngxScrollToEasing: ScrollToAnimationEasing;
  ngxScrollToOffset: number;
  ngxScrollToOffsetMap: ScrollToOffsetMap;
 
  constructor() {
 
    this.ngxScrollToDestination = 'destination-1';
    
    this.ngxScrollToDuration = 1500;
    this.ngxScrollToEasing = 'easeOutElastic';
    this.ngxScrollToOffset = 300;
    this.ngxScrollToOffsetMap = new Map();
    this.ngxScrollToOffsetMap
      .set(480, 100)
      .set(768, 200)
      .set(1240, 300)
      .set(1920, 400)
 
  }

  ngOnInit(): void {
  }

}
