import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import {map} from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'c31z' })
};

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const  body = res;
    return body || {};
  }

  public getData(url: string): Observable<any> {


    return this.http.get(url, httpOptions).pipe(
      map(this.extractData));

  }

}