import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/myservices/data.service';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})
export class GaleriaComponent implements OnInit {
  cards: any[];

  constructor(private myData: DataService) { }

  ngOnInit(): void {
    
    this.myData.getData('../assets/certyfikaty.json').subscribe(
     (data) => { 
       this.cards = data;    
     
         
     });
     
   }

}
