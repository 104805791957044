import { Component } from '@angular/core';
import { DataService } from './myservices/data.service';
import { Galleryitem } from './myclasses/galleryitem';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    // animation triggers go here
  ]
})
export class AppComponent {
  title = 'wbednarz2020';
  public product:Galleryitem;
  products: Galleryitem[];
  constructor(private myData: DataService) { }
cartProductList=[];
ngOnInit(): void {
  
  this.myData.getData('../assets/certyfikaty.json').subscribe(
    (data) => { this.products = data;
                console.log(data);
    });
}
}
