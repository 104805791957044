<section id="zakresbadan" #destinationRef>
    <div class="container-xl">
        
        <div class="row">           
                <h2 class="display-4 font-weight-normal">Zakres badań</h2>
        </div>
       
          <div class= "row border-bottom">Opieka przedporodowa i poporodowa</div>        
          <div class="row border-bottom">Leczenie dolegliwości okresu przekwitania, leczenie zaburzeń menopauzy</div>        
          <div class="row border-bottom"> Antykoncepcja dobór metod, zakładanie wkładek wewnątrzmacicznych (min. Mirena * Lady Insert)</div>
          <div class="row border-bottom"> Leczenie niepłodnosci kobiecej oraz męskiej</div>
            <div class="row border-bottom">Leczenie nietrzymania moczu</div>
            <div class="row border-bottom">Leczenie zaburzeń miesiączkowania</div>
            <div class="row border-bottom"> USG przezpochwowe</div>
            <div class="row border-bottom">USG Piersi</div>
            <div class="row border-bottom">USG przezbrzuszne</div>
            <div class="row border-bottom"> Badanie piersi</div>
            <div class="row border-bottom">Badania ginekologiczne</div>
            <div class="row border-bottom">Wymrażanie nadżerek oraz zmian skórnych</div>
            <div class="row border-bottom">Cytologia</div>
            <div class="row border-bottom">Badanie bakteriologiczne czystości pochwy</div>
            <div class="row border-bottom">Diagnostyka i leczenie stanów zapalnych narządu rodnego</div>
            <div class="row border-bottom">Nosicielstwo Chlamydii</div>
            <div class="row border-bottom">Kolposkopia prosta i rozszerzona- nosicielstwo Wirusów w tym HPV (patrz WWW.HPV.pl ) a także wczesne wykrywanie zmian nowotworowych</div>
            <div class="row border-bottom">Testy post coitalne * test penetracji plemników od szyjki</div>
            <div class="row border-bottom">Biocenoza pochwy- badania mikroskopowe</div>
            <div class="row border-bottom">Hormony</div>
            <div class="row border-bottom">Posiewy z kanału szyjki</div>
        </div>        
      
        
  
  </section>
 