
    <div class="container-xl">
        <p class="lead form-inline my-2 my-lg-0 float-right"> rejestracja <br>601 64 23 93</p>      
        <h1 class="font-weight-normal ">Lek. med. Wiesław Bednarz</h1>
          <p class="lead font-weight-normal"> SPECJALISTYCZNY GABINET GINEKOLOGICZNO - POŁOŻNICZY</p>
         
         
        </div>
       
  
      <nav class="navbar sticky-top navbar-light bg-light fixed-top">
          <div class="container">
            <a class="navbar-brand" href="#"><div class="container">
              <div class="box rotate">WB</div>
            </div></a>
            <a class="nav-link " href="#zakresbadan" [ngxScrollTo]="'#zakresbadan'">Zakres badań</a>
            <a class="nav-link " href="#certyfikaty"  [ngxScrollTo]="'#certyfikaty'">Certyfikaty</a>
            <a class="nav-link " href="#praktyka" [ngxScrollTo]="'#praktyka'">Praktyka</a>
            <a class="nav-link " href="#gabinet" [ngxScrollTo]="'#gabinet'">Gabinet</a>
            <a class="nav-link " href="#kontakt" [ngxScrollTo]="'#kontakt'">Kontakt</a>
           
          </div>
      </nav>
    

<app-main></app-main>