import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../myservices/data.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { scaleIn, scaleOut, fadeIn, fadeOut } from '../myclasses/carouselanimation';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'], 
  animations: [
    
    trigger("certyfikaty", [
      transition("void => *", [useAnimation(fadeIn, {params: { time: '3000ms' }} )]),
      transition("* => void", [useAnimation(fadeOut, {params: { time: '4000ms' }} )]),
    ])
  ]
})
export class CarouselComponent implements OnInit {
  cards: any;
  
  currentSlide: number;
  @Input() slides;
  exp="goAnimate";
  
  constructor(private myData: DataService){}

  ngOnInit(): void {
    this.myData.getData('../assets/certyfikaty.json').subscribe(
      (data) => { 
       this.cards = data;    
       this.slides = this.chunk(this.cards, 4);
          this.currentSlide=this.slides.length-1;
          this.onNextClick();
          console.log(this.currentSlide+' currentSlide');
  }


     ) }
     chunk(arr, chunkSize) {
      let R = [];
      for (let i = 0, len = arr.length; i < len; i += chunkSize) {
        R.push(arr.slice(i, i + chunkSize));
      }
      return R;
    }
    goAnimate() {

      this.exp === 'goAnimate'? this.exp = 'goneAnimate':  this.exp = 'goAnimate';
    }
    onNextClick() {
      
      const next = this.currentSlide + 1;
      this.currentSlide = next === this.slides.length ? 0 : next;
      console.log("next clicked, new current slide is: ", this.currentSlide);
   
      /*this.currentSlide = next === this.slides.length ? 0 : next;
        
     if(this.currentSlide ===   this.slides.length-1){this.currentSlide =0}else{ this.currentSlide=this.currentSlide+1;};
     console.log(  this.slides.length-1 +"next clicked, new current slide is: ", this.currentSlide);
     this.goAnimate();*/

    }
    onPreviousClick() {
     /* const previous = this.currentSlide - 1;
      this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
      console.log("previous clicked, new current slide is: ", this.currentSlide);*/
      const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    console.log("previous clicked, new current slide is: ", this.currentSlide);
    }

    onDone($event) {
     
        //this.currentSlide < this.slides.length - 1 ? this.currentSlide ++ : this.currentSlide--;

        console.log("załadowany: ", this.currentSlide);
      
    }
}
