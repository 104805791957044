<section id="praktyka" #destinationRef>
    <div class="container-xl">
        
        <div class="row">           
                <h2 class="display-4 font-weight-normal">Praktyka</h2>
        </div>
       
        <div class="row"> <h3>  Dr Wiesław Bednarz</h3></div>
     
              <div class= "row border-bottom">1985   Ukończył Wydział Lekarski Akademii Medycznej w Gdańsku</div>        
          <div class="row border-bottom">1986   Etat na Oddziale Ginekologii i Położnictwa Szpitala Miejskiego w Gdyni</div>        
          <div class="row border-bottom">  1990   uzyskał pierwszy stopień specjalizacji z zakresu Położnictwa i ginekologii , obejmuje etat młodszego asystenta na Oddziale Położniczo Ginekologicznym Szpitala Miejskiego w Gdyni</div>
          <div class="row border-bottom">     1996   zdany egzamin specjalizacyjny drugiego stopnia z zakresu położnictwa i ginekologii. W tym też roku obejmuje etat starszego asystenta Oddziału Położniczo Ginekologicznego Szpitala Miejskiego w Gdyni.</div>
            <div class="row border-bottom">1997 Indywidualna Specjalistyczną Praktykę Lekarską w zakresie Położnictwa i Ginekologii, któramieści sie w Gdyni przy ulicy Śląskiej 51/9. Posiada zezwolenie Okręgowej Izby Lekarskiej oraz wpis do Rejestru Okręgowej Izby Lekarskiej w Gdańsku</div>
            <div class="row border-bottom"> 2002 Przewodniczący Komisji Rewizyjnej Zarządu Regionu Pomorskiego Związku Zawodowego Lekarzy</div>
            <div class="row border-bottom"> 2005 Prezes Stowarzyszenia Pro Feminae </div>
            <div class="row border-bottom">Wieloletni członek Polskiego Towarzystwa Ginekologicznego. Od czasu ukończenia studiów uczestniczył w dużej liczbie kursów doskonalących i kształcących jak np. Ultrasonogorafia, Kolposkopia, Endokrynologia, Cytologia, Opieka prenatalna itd
            </div>
                 
      
        
    </div>
  </section>
  
