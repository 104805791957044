<section id="kontakt" class="mb-4">
    <h2 class="display-4 font-weight-normal text-center mx-auto">Kontakt</h2>
   <div class="col-md-5 p-lg-5 mx-auto my-5 mt-5 pb-1">
            
    
       <p class="lead font-weight-normal text-center"> Lek. med. Wiesław Bednarz</p>
       <p class="lead font-weight-normal text-center"> SPECJALISTYCZNY GABINET GINEKOLOGICZNO - POŁOŻNICZY</p>
       <p class="lead font-weight-normal text-center text-center">Gdynia, ul. Śląska 51/9 * od poniedziałku do piatku po 17.00 </p>
       <p class="lead font-weight-normal text-center">rejestracja telefoniczna: 601 64 23 93</p>
      
     </div>
   <!--Section heading-->
  
 
   <div class="text-center"><img src="assets/slider/mapa.jpg" alt="dojazd mapa">    </div>
  
 
 </section>
